@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');
@import '@ingka/svg-icon/dist/style.css';
@import '@ingka/button/dist/style.css';
@import '@ingka/focus/dist/style.css';
@import '@ingka/text/dist/style.css';
@import '@ingka/carousel/dist/style.css';
@import '@ingka/tabs/dist/style.css';
@import '@ingka/table/dist/style.css';
@import '@ingka/pill/dist/style.css';
@import '@ingka/forms/dist/style.css';
@import '@ingka/search/dist/style.css';
@import '@ingka/accordion/dist/style.css';
@import '@ingka/animations/style.scss';
@import '@ingka/tooltip/dist/style.css';
@import '@ingka/banner/dist/style.css';
@import '@ingka/toast/dist/style.css';
@import '@ingka/modal/dist/style.css';
@import '@ingka/loading/dist/style.css';
@import '@ingka/toggle/dist/style.css';
@import '@ingka/list/dist/style.css';
@import '@ingka/inline-message/dist/style.css';
@import '@ingka/hyperlink/dist/style.css';

@font-face {
  font-family: 'Noto Sans IKEA';
  font-style: normal;
  font-weight: 400;
  src: url('data:font/woff2;base64,d09GMgABAAAAAAUIAA0AAAAADAAAAAS1AAIAQgAAAAAAAAAAAAAAAAAAAAAAAAAAGjAbIByCJAZgADQKgxSCLgE2AiQDJAsUAAQgBYxyB2AbpQoRlaRJkP08jI2lR28bLyeF+UVSNKHo57Rb04MnXu+/M5ObPGhBvg9qYXagqt1arqAWUgenZX6V5wXAqlOp12X1nusaY54pOINllBho/e/3a3VF25dQ1u70HyKh0tLuu/jB/M7LeBKz6ZohSigiWqEunelEQiKSGiVnnjW1C7vXnVJCcOHC8Xn1xxGUMADQCEUQvAiCAeoFtsDL/TSjBZKbX9oHLwbAdQGo+BKOYNLaZPTCWVNoMX7W2Mn0mDJyzjSi9AGy0bKS3FZU1lRnt6KSKNADT1ZlZSGpOVl9WpFa9DHKsgqTt3BFqVme1c+srijj/GBAFMvx6jlm5Mgpc2gyOjp7NG1mw+BOs2f3iqUTkBpke5WFwU8r0umDQLXFQaemjZODuNuJn1r4zmwUxeGThUFECTu24nm8YOEARrNkEzboGh0AlDz+RWQYUckGDdhqbKNqAAEAACioqCiQTIooNt3/P8J0l/YgBwG0kd6EVhhAAQuoh4UA9TC0AgwGAYpdFyhyXfccAgCAD4A6gB+YACwBAAwAipwixgRrm7eLBCMBO2RGhexgKCj97X4Soo/p22fNqQay/WRg7fnjYgfMotuB7Xb7rrnaQQ5diXGdyjs7b7okVkDnu0t/q58E6KN96bMGLd9+aMHMuwxr783xxI2Jo4vG1tRJqakb+3DVfZc6WBcbxd8pvVuv8aWj3a8OcvzYkYy8OShW+tuWJZdOLGkRa9UdFHx9ceqAVOZ5m51KLFrSuceS2F39Nw3v25f20czI6cxw7MPYfXK6VyQK+7stzH15Ofzb6t6//QcDIICSXTI0Wmd4JPWb17LeA7fCjSIA996mx7nH3Kn80pvgoAAIWFaqA4gQ7ZZBOOb50B4Mkxo6SW3yt0pzRsp+mvGFYhlNU2lLez655xHAR20UAPlj8QIQ4RwWYnzAMOBDQjLDSAmziyz6cJgMxfwmmyaSSw7pMow85Mo+8tJFnpOPTuonP9kapQDpOoeCpOoxCukO/fo5wsTa+stmlP6tUZqBFg2adAFWRCpCYJN4NYBJFAFXAZyTfnRoc/RrHIkJuInEAZvdYgLsBZsEoCT6UwJs07lIZZGwLWo0FB05ZnU6rcaknAO5kAs5kIJMttXp0jUXhHhWt8UZ4uYq/RvqYJRdJAC2wseYRrhbmLQJh9RVGvqfokoElfPCFUvmWCGv2vqOIka+Cn3g+wdpRt/PTTU0n6LpiLq3aKihtKcctxeN6yjN9VRXQ3XhrDjn0WKn48w3+q6bEUsu2HjVdMJFU7qRySOJjIeJq6JkSXVgKyIahe03e+CU4+wk1dl7sbsyZi9xNfmydKAK/sNk2/bNWJubBBBbZ5nJDdi0lAGmrgJ35eDX5r4+Pe6uc3K9To6yWcgOPl2B2ek4Z2PzYTy0ZG77D7Db0CpdjaZhYJ3oL7VwukGIaWKJzBa1aq4/YpNuBiTZAcdv8YxkAcYSklGlhbLBmyLe5ZjBI4wNZuuZ2MiCwxZFuawkGT8tf8ryNzOXMx4XyYaOPRbEeaa2T9nVdElUq56oeqN/474EAO6+Fsf7+UBULLpRFSO2OOJxiv67psLZ3kPG1HkmAMNnhnO02he8AAAAAA==')
    format('woff2');
  unicode-range: U+0049-004A;
}
@font-face {
  font-family: 'Noto Sans IKEA';
  font-style: normal;
  font-weight: 700;
  src: url('data:font/woff2;base64,d09GMgABAAAAAAT8AA0AAAAADAQAAASsAAIAQgAAAAAAAAAAAAAAAAAAAAAAAAAAGjAbIByCJAZgADQKgzCCPgE2AiQDJAsUAAQgBYxaB2AbnQoRlaQ/EPw8sI2tC+1gZWi7B4ZBIyj0Q/4c0OOB//6s7nv5SQkNVVfT1YA9wlrifs6syLzSee00cZ3VJhEdI/RLjNxDKgFQtv///36uTrTNs97Tf4pr7cn8zvyelzfMukpIhEL0LiJ15FE5lEBIpFaxrdLUa33YiMgiIl5JPo/8uSAqBtQWBUGuIEhQ/eYOfDxfhUphyLAxU+RKIBtooUMelPS07uKdm9aoXL5p6Wrt1yzcsk6VKRgkjh09pKEJkycNamiCKrSXM3DChBH6DB44paE+I5Fj7MARbGUfP8Y5buA056TxY2XuXPIdk9AYr0kWLlyzRd3FVZsXa7z52Wm5eXPHLs8qREUAd6Uk8jXUzxQBDygjPpxpmXhS35TylVL2lhZFGS6kJAJFxdAd5Yi8l2WQxGVxhjTx1DqE0fFfJMxTFUYQC3Jjkk5iTBAAYPj48cNDQyONStr9/42kXWhGuBNi4/g2GkoQkUJ1KQHVJRoikQgYlY2R2dmnBQB5oBz5WIczgAREYeZPksLy5BPLCssK0kXJoqJ0YVFhmJ6aFopMiVOnnnq8Zrj6WMHpZx4J6YLkwKefFjQsGXq9ebj/tchUQeSc3AvNNLmMt+7+7Av3S+e9fevby6u6Lulq6dBp01q8P81OW3dL8Uf1H/9l3y/Nqte9d3uX26/fNqfHhqVVZr19/W0X8pvfHHZgWn5FvcufeD6//lnXhg07P8zRvEY9qm+sfrNO8zm9d5zfOLHTwlltVEwYWbFmZOW4xuNGVK4ZAY9Q9ECdJR9vePnTMrKn8nytLwYB0aArr5b+Pb+kz2+5qdR38Elx7RL44tt+U7Oy5dxf+bcFGREE4izEGSSb+rOE/o2RF2eYF45qGEZnhdFmhTdVhArDwmUNwmwVoTL7YkCechEEAJALSjwtJSR5mAeCoJd5EOW6ASnj3AWJIb6HtLqhCjJ6hDGQY0g4ArlahzchT8vwM+QbFOtCgX5xDhTqE2+Bongtfv4zinVBT68x5vRvybwV1FXVtIA5s4kQLBOvDAyjCJzYKLD3KGg/6JftWBmcnUTAcj0dgo2jlABOoj/tZrAaceTXlVV1nTkw3+/nA/LxIlEkinjBC9+qtFS68lDizeiAEDMSSpdaHZKypgNYax0fY7Jhv75XNLjNIeTUPyUIgf03lBgGTdCW0ex0QSdOylAJuL7MLhPBNyuWj8dYTfsqM+O3XWZscKj3AnGFUVrJsBWuiKyIVcwU0opmPqnL21Oc8VCDFz6Sfn3No+tnReRhInYpS6yhNUVvFJm43IB99pVJhpUbIV1x9qOiq8n55oFC9NdOtUNupjqldJmz0hYMz2A5UABGWBT64sn35WWxv17u2uHpNNzRcirR3J+K5nif1E0kgaNYr5tJ3sQnWC+pIodCcgktDPxSi2IBOemkWEX0Wzj84Izo73bw4mhQuAMevRgEInemmswxkRhTfZyXYLLiY8Y/BpKU1csa7wcMfyTyyVUa+HOmxZlJ/GpuUMOd/VqQeAE3U0XOMqGobJVMJYBf3OSB7G11k+flEWJIaasUkpAOmZCTGfnvNeXMamlLqOklQeHIo/kyVcsLAA==')
    format('woff2');
  unicode-range: U+0049-004A;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans IKEA', 'Noto Sans', 'Roboto', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  color: #111111;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page .banner.banner--open {
  position: unset;
}
