.hide {
  display: none;
}

.page {
    display: flex;
    flex-direction: column;
}

.header {
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1500px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid lightgray;
    padding: 1em;
}
.table {
  margin-top: .5rem;
  margin-bottom: 1rem;
  width: 100%;
}
.definition-list--glossary tbody th {
  width: 33%;
  min-width: 9rem;
}

.vertical-center td {
    vertical-align: middle;
}
.header a,.logo {
    height: 100%;
}

.lightbox {
    z-index: 3 !important;
}

.margin-top-1 {
    margin-top: 1em;
}

.accordion-bottom-border {
  border-bottom: 1px solid #dfdfdf;
}

.adminbtn {
  float: right;
}

.call-out {
  background: #111;
}
.call-out__title {
  color: #fff;
}
.call-out__description {
  color: #fff;
}

.input-field {
  margin-bottom: 1.25rem;
}

.text-area {
  margin-bottom: 1.25rem;
}

.prompt__content-wrapper {
  min-width: 31.5rem;
}

.url-search-results {
  margin-top: 2rem;
}
.url-search-results td {
  display: block;
  border-bottom: none;
}
